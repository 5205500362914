<template>
  <!-- Semis -->
  <div class="fiche-pesees-semis-edit-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Fiche de pesées</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn color="primary"
            hollow
            text="Paramètre de la fiche"
            @click="openModal('params', parameters)" />
          <Btn color="primary"
            hollow
            text="Exporter la fiche de pesées"
            @click="download('xls', 'evenement', $route.params.tid)" />
        </div>
        <form>
          <Table :headers="headers"
            :static-headers="staticHeaders"
            :items="formattedModalities"
            :hiddenItems="['id', 'ordre', 'unite', 'densite', 'modality_id', 'semence_id', 'facteur_id']"
            :tools="true"
            @table-row-validate="validate($event)">

            <template v-slot:tools="{ item }">
              <Btn @click="openModal('edit', item.item)"
                icon="create"
                color="white"
                title="Modifier la modalité" />
            </template>

          </Table>
        </form>
      </Container>
    </Section>
  </div>

  <!-- Modale pour une modalité -->
  <Modal title="Edition d'une modalité"
    :active="modal.edit"
    :data="modalData"
    @modal-close="modal.edit = false"
    :containForm="true"
    :modalValidationSchema="modaliteSchema"
    @modal-form-submitted="onSubmit(modalData.id, $event)">
    <template v-slot:modal-body="{ data }">

      <Input label="Faculté germinative"
        type="number"
        :options="{ step: 0.001, min: 0, max: 100 }"
        input-after="%"
        id="faculte_germinative" />

      <Input label="Pesée réelle"
        id="pesee_reelle"
        type="number"
        :options="{ step: 0.001, min: 0 }"
        :inputAfter="data?.suffixe" />

      <Input label="Densité"
        id="densite" />

      <SelectExtended label="Unité"
        id="unite"
        optionKey="uid"
        optionValue="valeur"
        apiEndpoint="unite"
        :apiParams="{ sort: 'valeur.ASC', limit: 0 }" />

      <Input label="PMG"
        type="number"
        :options="{ step: 0.001, min: 0 }"
        id="pmg" />

      <Input type="textarea"
        label="Observations"
        id="observations" />

      <input id="modality_id"
        type="hidden">
      <input id="semence_id"
        type="hidden">
      <input id="facteur_id"
        type="hidden">

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler"
        @click="modal.edit = false" />
      <Btn text="Enregistrer"
        color="primary"
        type="submit" />
    </template>
  </Modal>

  <!-- Modale des paramétrages de la fiche pesée -->
  <Modal type="parameters"
    :title="evenement_nom ?
      `Paramétrage de la fiche de pesées : ${evenement_nom}` :
      `Paramétrage de la fiche de pesées`"
    :active="modal.params"
    :data="modalData"
    @modal-close="modal.params = false"
    :containForm="true"
    :modalValidationSchema="parametersSchema"
    @modal-form-submitted="setParameters($event)">
    <template v-slot:modal-body>

      <Input label="Marge de sécurité pesées"
        type="number"
        input-after="%"
        :options="{ step: 0.001, min: 0, max: 100 }"
        id="marge_securite_pesee" />

      <Radio v-if="unites"
        label="Unité de calcul quantités"
        id="unite_uid"
        :items="unites"
        inputStyle="inline" />

      <h4>Données par defaut</h4>
      <p>Attention, les valeurs vont affecter toutes les modalités et écraser les anciennes valeurs.</p>

      <Input label="Densité"
        id="densite" />

      <SelectExtended label="Unité"
        id="unite"
        optionKey="uid"
        optionValue="valeur"
        apiEndpoint="unite"
        :apiParams="{ sort: 'valeur.ASC', limit: 0 }" />

      <Input label="PMG"
        type="number"
        :options="{ step: 0.001, min: 0 }"
        id="pmg" />

      <Input label="Faculté germinative"
        type="number"
        :options="{ step: 0.001, min: 0, max: 100 }"
        input-after="%"
        id="faculte_germinative" />

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler"
        @click="modal.params = false" />
      <Btn text="Enregistrer"
        color="primary"
        type="submit" />
    </template>
  </Modal>
  <Loader :active="loading" />
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'FichePeseesSemisSubview',
  components: {
    Btn,
    Table,
    Container,
    SelectExtended,
    Input,
    Modal,
    Radio,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {},
  },

  data() {
    const parametersSchema = this.yup.object().shape({
      marge_securite_pesee: this.yup.string().nullable(),
      pesee_reelle: this.yup.string().nullable(),
      observations: this.yup.string().nullable(),
      unite_uid: this.yup.object().nullable(),
      unite: this.yup.object().nullable(),
      pmg: this.yup.string().nullable(),
      faculte_germinative: this.yup.string().nullable(),
      traitement: this.yup.string().nullable(),
    })
    const modaliteSchema = this.yup.object().shape({
      faculte_germinative: this.yup.string().nullable(),
      pesee_reelle: this.yup.string().nullable(),
      densite: this.yup.string().nullable(),
      unite: this.yup.object().nullable(),
      pmg: this.yup.string().nullable(),
      observations: this.yup.string().nullable(),
      modality_id: this.yup.string().nullable(),
      semence_id: this.yup.string().nullable(),
      facteur_id: this.yup.string().nullable(),
    })

    return {
      parametersSchema,
      modaliteSchema,
      modal: {
        params: false,
        edit: false,
        params_moda: false,
      },
      modalData: {},
      loading: true,
      headers: [],
      staticHeaders: [
        'Variété',
        'Densité Semis',
        'PMG',
        'Faculté <br>germinative',
        'Nb rép.',
        'Surface <br>implantation',
        'Quant. calculée <br>par microparcelle',
        'Pesée <br>réelle',
        'Réalisé <br>par',
        'Réalisé <br>le',
        'Observations',
        'Validation',
      ],
      parameters: null,
      modalities: {},
      mods: [],
      essai: {},
      formattedModalities: [],
      fiche_pesee_id: null,
      evenement_nom: null,
      unites: [],
    }
  },
  created() {
    this.loading = true
    this.getEssai()
    this.setComponent()
  },
  methods: {
    validate(event) {
      const values = { ...event }
      // eslint-disable-next-line
      values.trigramme = (this.$store.state.auth.user.data.prenom.substring(0, 2) + this.$store.state.auth.user.data.nom.substring(0, 1)).toUpperCase()
      values.date = this.helperService.formatDateForApi(new Date())
      this.onSubmit(values.id, values)
    },

    openModal(modal, data) {
      this.modalData = data
      if (modal === 'edit') {
        this.modalData = this.getMods(data)
      }
      this.modal[modal] = true
    },

    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
        },
      )
    },

    setComponent() {
      this.getModalites().then((rm) => {
        this.modalities = rm
        this.getFormatModalities(this.modalities).then((rfm) => {
          this.formattedModalities = rfm
          this.setFicheParams()
        })
      })
    },

    setFicheParams() {
      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/semis/pesee/${this.fiche_pesee_id}`
      this.fetchService.get(endpoint).then((res) => {
        const datas = res.data
        datas.unite_uid = datas.unite?.uid
        if (!datas.marge_securite_pesee) {
          datas.marge_securite_pesee = this.parseParameterValue('SEMIS_PESEE_MARGEPESEE')
        }

        if (!datas.unite_uid) {
          datas.unite_uid = this.parseParameterValue('SEMIS_PESEE_UNITECALCUL')
        }

        this.parameters = datas
        this.unites = this.getUnites()
      })
    },

    parseParameterValue(parameterName) {
      try {
        const parameterValue = JSON.parse(this.$store.getters['app/parameters']([parameterName])[0].valeur)
        // eslint-disable-next-line
        return typeof parameterValue === 'string' && parameterValue ? parameterValue : typeof parameterValue === 'object' && parameterValue ? parameterValue.key : null
      } catch (e) {
        console.log(e)
        return null
      }
    },

    getMods(modalitie) {
      return {
        id: modalitie.id,
        pmg: modalitie.pmg,
        faculte_germinative: modalitie.faculte_germinative,
        pesee_reelle: this.taskService.getValueOnly(modalitie.pesee_reelle),
        trigramme: modalitie.trigramme,
        date: modalitie.date || null,
        observations: modalitie.observations,
        suffixe: this.taskService.getSuffixe(modalitie.pesee_reelle),
        densite: modalitie.densite,
        unite: modalitie.unite,
        modality_id: modalitie.modality_id,
        semence_id: modalitie.semence_id,
        facteur_id: modalitie.facteur_id,
      }
    },

    async onSubmit(id, values) {
      const promisesArray = []
      const params = { ...values }

      params.date = params.date
        ? this.helperService.formatDateForApi(params.date.slice(0, 10))
        : null
      params.trigramme = params.trigramme ? params.trigramme : null
      params.pesee_reelle = this.taskService.getValueOnly(values.pesee_reelle)

      Object.keys(params).forEach((key) => {
        if (params[key] === '') {
          params[key] = null
        }
      })

      promisesArray.push(
        this.fetchService.put(`essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/semis/pesee/modalite/${id}`, params),
      )

      if (params?.unite && params?.unite?.key) {
        promisesArray.push(
          this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${params.modality_id}/semence`, [
            {
              id: params.semence_id,
              facteur_id: params.facteur_id,
              unite_uid: params.unite.key,
            },
          ]),
        )
      }

      if (params?.densite) {
        promisesArray.push(
          this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${params.modality_id}/semence`, [
            {
              id: params.semence_id,
              facteur_id: params.facteur_id,
              densite: params.densite,
            },
          ]),
        )
      }

      if (params?.pmg) {
        promisesArray.push(
          this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${params.modality_id}/semence`, [
            {
              id: params.semence_id,
              facteur_id: params.facteur_id,
              pmg: params.pmg,
            },
          ]),
        )
      }

      Promise.all(promisesArray).then(async () => {
        this.mods = []
        this.loading = true
        await this.setComponent()
        this.modal.edit = false
        this.emitter.emit('alert', {
          type: 'success',
          content: 'La pesée a bien été modifiée.',
        })
      })
    },

    getModalites() {
      return new Promise((resolve) => {
        const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/semis/pesee/modalite`
        this.fetchService.get(endpoint, { limit: 0 }).then((res) => {
          resolve(res.data)
        })
      })
    },

    getFormatModalities(modalities) {
      return new Promise((resolve) => {
        let datas = []
        modalities.forEach((modality) => {
          const data = {
            id: modality.id,
            ordre: modality.modalite?.ordre,
            variete: modality.produit?.designation,
            densite_semis: this.taskService.concatValueUnit(
              modality.semence?.densite ? modality.semence.densite : '',
              modality.semence?.unite && modality.semence.unite ? modality.semence.unite.valeur : '',
            ),
            pmg: modality.pmg ? modality.pmg : modality.semence.pmg,
            faculte_germinative: modality.faculte_germinative,
            nb_rep: modality.nb_repetitions,
            surface: this.taskService.concatValueUnit(
              modality.surface_implantation ?? 'NC',
              modality.surface_implantation ? 'm²' : '',
            ),
            quantite_microparcelle: this.taskService.concatValueUnit(
              modality.quantite_microparcelle ? this.helperService.round(modality.quantite_microparcelle, 3) : 'NC',
              modality.quantite_microparcelle ? modality.fiche_semis_pesee?.unite?.valeur : '',
            ),
            pesee_reelle: this.taskService.concatValueUnit(
              modality.pesee_reelle ? this.helperService.round(modality.pesee_reelle, 3) : 'NC',
              modality.pesee_reelle ? modality.fiche_semis_pesee?.unite?.valeur : '',
            ),
            trigramme: modality.trigramme,
            date: modality.date?.split(' ').shift(),
            observations: modality.observations,
            validation: modality.date !== null && modality.trigramme !== null,
            densite: modality?.semence?.densite,
            unite: { key: modality?.semence?.unite?.uid, value: modality?.semence?.unite?.valeur },
            modality_id: modality.modalite?.id,
            semence_id: modality?.semence?.id,
            facteur_id: modality?.semence?.facteur?.id,
          }
          this.fiche_pesee_id = modality.fiche_semis_pesee.id
          this.evenement_nom = modality.evenement_designation
          datas.push(data)
        })
        datas = this.helperService.sortByOrder(datas)
        this.loading = false

        console.log('datas', datas)

        resolve(datas)
      })
    },

    download(type, ressource, rid) {
      this.loading = true
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/fiche-pesee`
      const filename = `evenement_${rid}_fiche_pesee_semis`
      this.fileService.downloadFile(endpoint, filename, type)
      this.loading = false
    },

    async setParameters(parameters) {
      const promisesArray = []

      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          // eslint-disable-next-line no-param-reassign
          parameters[key] = null
        }
      })
      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/semis/pesee/${this.fiche_pesee_id}`
      promisesArray.push(this.fetchService.put(endpoint, parameters))

      this.modalities.forEach((modalite) => {
        if (parameters?.faculte_germinative) {
          promisesArray.push(
            this.fetchService.put(`essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/semis/pesee/modalite/${modalite.id}`,
              { faculte_germinative: parameters.faculte_germinative }),
          )
        }

        if (parameters?.unite && parameters?.unite?.key) {
          promisesArray.push(
            this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${modalite.modalite.id}/semence`, [
              {
                id: modalite.semence.id,
                facteur_id: modalite.semence.facteur.id,
                unite_uid: parameters.unite.key,
              },
            ]),
          )
        }

        if (parameters?.densite) {
          promisesArray.push(
            this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${modalite.modalite.id}/semence`, [
              {
                id: modalite.semence.id,
                facteur_id: modalite.semence.facteur.id,
                densite: parameters.densite,
              },
            ]),
          )
        }

        if (parameters?.pmg) {
          promisesArray.push(
            this.fetchService.put(`essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/semis/pesee/modalite/${modalite.id}`,
              { pmg: parameters.pmg }),
          )
          promisesArray.push(
            this.fetchService.put(`protocole/${this.essai.protocole.id}/modalite/${modalite.modalite.id}/semence`, [
              {
                id: modalite.semence.id,
                facteur_id: modalite.semence.facteur.id,
                pmg: parameters.pmg,
              },
            ]),
          )
        }
      })

      Promise.all(promisesArray).then(async () => {
        await this.setComponent()
        this.modal.params = false
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Les paramètres de la fiche de pesées ont bien été modifiés.',
        })
      })
    },

    getUnites() {
      this.fetchService.get('unite?filters=uid:in(NBGRAINS,GRAMME)').then((ru) => {
        const datas = ru.data
        const result = []
        datas.forEach((data) => {
          result.push({
            label: data.valeur,
            value: data.uid,
          })
        })
        this.unites = result
      })
    },
  },
}
</script>
